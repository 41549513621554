import { default as _91slug_93KObAeJ0Y1fMeta } from "/home/forge/uclermarket.com.tr/pages/[slug].vue?macro=true";
import { default as address9zGuiowZnsMeta } from "/home/forge/uclermarket.com.tr/pages/account/address.vue?macro=true";
import { default as _91id_93aAvg1gOgb3Meta } from "/home/forge/uclermarket.com.tr/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93RuV0BDyZAwMeta } from "/home/forge/uclermarket.com.tr/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexzHuIoKIDesMeta } from "/home/forge/uclermarket.com.tr/pages/account/orders/index.vue?macro=true";
import { default as reviewEN3y1BEwA9Meta } from "/home/forge/uclermarket.com.tr/pages/account/review.vue?macro=true";
import { default as _91id_937pZHLHbRlsMeta } from "/home/forge/uclermarket.com.tr/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexJptCHp5thZMeta } from "/home/forge/uclermarket.com.tr/pages/account/statement/index.vue?macro=true";
import { default as useriK0JEKuwjvMeta } from "/home/forge/uclermarket.com.tr/pages/account/user.vue?macro=true";
import { default as wishlistEdjccJWIwnMeta } from "/home/forge/uclermarket.com.tr/pages/account/wishlist.vue?macro=true";
import { default as cartfkispfTd4nMeta } from "/home/forge/uclermarket.com.tr/pages/cart.vue?macro=true";
import { default as indexixeMp2beSXMeta } from "/home/forge/uclermarket.com.tr/pages/checkout/index.vue?macro=true";
import { default as payment7crXXCvb8xMeta } from "/home/forge/uclermarket.com.tr/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientJVuYUHsP7BMeta } from "/home/forge/uclermarket.com.tr/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/uclermarket.com.tr/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestcVbWuqWKtvMeta } from "/home/forge/uclermarket.com.tr/pages/customer/request.vue?macro=true";
import { default as successYXwzz0zVWfMeta } from "/home/forge/uclermarket.com.tr/pages/customer/success.vue?macro=true";
import { default as iletisim9Pnzg6isvsMeta } from "/home/forge/uclermarket.com.tr/pages/iletisim.vue?macro=true";
import { default as indexzfbSoLyExPMeta } from "/home/forge/uclermarket.com.tr/pages/index.vue?macro=true";
import { default as loginDJKSleOvxkMeta } from "/home/forge/uclermarket.com.tr/pages/login.vue?macro=true";
import { default as offer0P0LSml3HXMeta } from "/home/forge/uclermarket.com.tr/pages/offer.vue?macro=true";
import { default as statusnKo6fvvukrMeta } from "/home/forge/uclermarket.com.tr/pages/order/status.vue?macro=true";
import { default as trackKTxvXHz3esMeta } from "/home/forge/uclermarket.com.tr/pages/order/track.vue?macro=true";
import { default as forgotsDNYihKzDmMeta } from "/home/forge/uclermarket.com.tr/pages/password/forgot.vue?macro=true";
import { default as resetuxSm8BYrM0Meta } from "/home/forge/uclermarket.com.tr/pages/password/reset.vue?macro=true";
import { default as payment_45notificationj4czNjwBi0Meta } from "/home/forge/uclermarket.com.tr/pages/payment-notification.vue?macro=true";
import { default as summaryzK2dKG3uDoMeta } from "/home/forge/uclermarket.com.tr/pages/payment/summary.vue?macro=true";
import { default as registerLSFJhbGzrTMeta } from "/home/forge/uclermarket.com.tr/pages/register.vue?macro=true";
import { default as searchSZmUoGG5W9Meta } from "/home/forge/uclermarket.com.tr/pages/search.vue?macro=true";
import { default as locationsOyrBXJs6EFMeta } from "/home/forge/uclermarket.com.tr/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93KObAeJ0Y1fMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: address9zGuiowZnsMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93aAvg1gOgb3Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93RuV0BDyZAwMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexzHuIoKIDesMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewEN3y1BEwA9Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_937pZHLHbRlsMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexJptCHp5thZMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: useriK0JEKuwjvMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistEdjccJWIwnMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/uclermarket.com.tr/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexixeMp2beSXMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: payment7crXXCvb8xMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/uclermarket.com.tr/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/uclermarket.com.tr/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/uclermarket.com.tr/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/uclermarket.com.tr/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: indexzfbSoLyExPMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginDJKSleOvxkMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offer0P0LSml3HXMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/uclermarket.com.tr/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/uclermarket.com.tr/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotsDNYihKzDmMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetuxSm8BYrM0Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/uclermarket.com.tr/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/uclermarket.com.tr/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerLSFJhbGzrTMeta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchSZmUoGG5W9Meta || {},
    component: () => import("/home/forge/uclermarket.com.tr/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/uclermarket.com.tr/modules/locationsPage/pages/locations.vue")
  }
]